#roundButton {
  background-color: #96D9FF;
  margin-bottom: 0px;
  width: 30px;
  height: 30px;
  border-radius: 35%;
  border: 10px solid #0065A6;
  outline: none;
  transition: all .2s cubic-bezier(0, 1.26, .8, 1.28);
}

#roundButton:hover {
  background-color: #96D9FF;
  cursor: pointer;
  border-color: #003557;
  transform: scale(1.2, 1.2);
}

#roundButton:active {
  border-color: #003557;
  background-color: #FFF;
}

#roundButton3 {

  background-color: #96D9FF;
  margin-bottom: 10px;
  margin-top: -20px;
  width: 35px;
  height: 35px;
  border: 10px solid #0065A6;
  border-radius: 35%;
  outline: none;
  transition: all .2s cubic-bezier(0, 1.26, .8, 1.28);
}

#roundButton3:hover {
  background-color: #96D9FF;
  cursor: pointer;
  border-color: #003557;
  transform: scale(1.2, 1.2);
}

#roundButton3:active {
  border-color: #003557;
  background-color: #FFF;
}




#flyoutMenu {
  width: 20vw;
  height: 100%;
  padding-top: 150px;
  background-color: rgb(67,170,140);
  position: fixed;
  top: 0;
  left: 0;
  transition: transform .3s
              cubic-bezier(0, .52, 0, 1);
  border-right: 5px solid #343A40;
  z-index: 1000;
}

#flyoutMenu.hide {
  transform: translate3d(-15vw, 0, 0);

}

#flyoutMenu.show {
  transform: translate3d(0vw, 0, 0);

}

#flyoutMenu.hide h4 {
  display: none;
}

#flyoutMenu h4 {
  text-decoration: none;
}

#flyoutMenu h4:hover {
  text-decoration: underline;
}



#flyoutMenu3 {
  width: 22vw;
  height: 100%;
  padding-top: 150px;
  padding-right: 20px;
  background-color: rgb(67,170,140);
  position: fixed;
  top: 0;
  left: 0;
  transition: transform .3s
              cubic-bezier(0, .52, 0, 1);
  border-right: 5px solid #343A40;
  z-index: 1000;
}

#flyoutMenu3.hide {
  transform: translate3d(-15vw, 0, 0);

}

#flyoutMenu3.show {
  transform: translate3d(0vw, 0, 0);

}

#flyoutMenu3.hide h4 {
  display: none;
}

#flyoutMenu3 h4 {
  text-decoration: none;
}

#flyoutMenu3 h4:hover {
  text-decoration: underline;
}
